.header{
    position: absolute;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $white;
    background-color: transparent;

    &-privacy{
        background-color: $white;
    }

    .container {
            @include desktop {
                padding-left: 40px;
                padding-right: 40px;
            }
        }

    .navigation{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
    }

    .burger-btn{
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;
        @include desktop{
            display: none;
        }
    }

    &-list{
        display: none;
        @include desktop{
            display:  flex;
            gap: 8px;
            border-radius: 40px;
            overflow: hidden;
            background: $white;
            border: 2px solid #105c9a;
        }

        &__item{
            a{
                font-family: $second-font;
                font-weight: 700;
                font-size: 14px;
                line-height: 120%;
                color: #105c9a;
                padding: 16px 22px;
                transition: $transition ;
                &:hover,&:focus{
                    color: $accent;
                }
            }

        }
    }

}


