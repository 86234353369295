.hero{
    background-color: $black;
    &.bg{
        background-position: top 0 center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('../img/1.webp');
        @include desktop{
            background-position: top -140px center;
        }
        

    }


    .content-wrapper{
        min-height: calc(100vh - 120px);
        display: flex;
        flex-direction: column;
        align-items: center;
       justify-content: center;
        @include desktop{
            justify-content: flex-end;
        }
    }
}


.about{
    .content-wrapper{
        padding: 40px 24px;
        display: flex;
        flex-direction: column;

        gap: 40px;

        text-align: center;
    }

    .title{
        font-weight: 700;
        font-size: 30px;
        text-transform: uppercase;
    }

    .image-title{
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
    }
}

.how{
    background: #f2f3ee;
    .content-wrapper{
        padding-left: 24px;
        padding-right: 24px;
        max-width: 952px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    
        gap: 40px;
        text-align: center;
    }

    .title{
        font-size: 30px;
        text-transform: uppercase;
    }
    .subtitle{
            font-size: 22px;
            text-transform: uppercase;
    }

    .list__item{
        margin-bottom: 24px;
    }

}

.gallery{
    background: #469ee6;
    color: $white;

    .title{
        font-weight: 700;
        font-size: 30px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 40px;
    }

    .list{
        display: flex;

        justify-content: center;
        align-items: center;
        gap: 32px;
        flex-wrap: wrap;
        padding: 24px;

        margin-bottom: 32px;

        &__item{
            width: 100%;
            border-radius: 8px;
            @include desktop{
                max-width: 450px;
            }
        }
        .image{
            width: 100%;
        }
    }

    .text{
        font-weight: 500;
        text-align: center;
    }
}

.contacts{
    .content-wrapper{
        border-radius: 16px;
            padding: 0 24px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 32px;

            @include tablet{
                flex-direction: row;
            }
    }
    .text-container{
            padding: 40px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            font-weight: 500;
    }

    .title{
        font-weight: 700;
            font-size: 30px;
            text-transform: uppercase;
    }

}
