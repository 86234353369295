.footer{
   padding-top: 30px;
   padding-bottom: 30px;

   background: #2f2f2f;
   color: $white;


   .navigation{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 30px;

      font-weight: 500;
         font-size: 14px;
      @include desktop{
         flex-direction: row;
      }

   }

   .link{
      margin-bottom: 16px;
      transition:  $transition;
      &:hover,&:focus{
         color: $accent;
      }
   }

   .text-wrapper{
      order: 2;
      @include desktop{
         order: 0;
      }
   }

   .list.tel-mail{
      a{
         font-size: 12px;
      }
      
   }
   
          
}
    

