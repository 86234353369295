@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&display=swap");
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
@media screen and (max-width: 767.98px) {
  .container {
    max-width: 430px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    padding: 0 100px;
    max-width: 1200px;
  }
}

.is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  height: auto;
}

a {
  text-decoration: none;
  color: currentColor;
  display: block;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  text-decoration: none;
}

.list {
  text-decoration: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

li:last-child {
  margin-bottom: 0 !important;
}

svg {
  fill: currentColor;
}

button {
  cursor: pointer;
}

address {
  font-style: normal;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html,
body {
  font-family: "Inter", sans-serif;
  background-color: #FFFFFF;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: #000000;
  margin: 0 auto;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

section {
  padding: 60px 0;
}

.logo {
  font-family: "Grandstander", sans-serif;
  font-weight: 800;
  font-size: 18px;
  text-transform: uppercase;
  color: #ae1412;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  max-width: max-content;
}
.logo:hover, .logo:focus {
  color: #469ee6;
}
.logo:active {
  color: second;
}

.app-link {
  max-width: max-content;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 12px;
}
.app-link:hover, .app-link:focus {
  -webkit-box-shadow: 0px 15px 12px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 15px 12px -8px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 15px 12px -8px rgba(0, 0, 0, 0.75);
}
.app-link:active {
  box-shadow: none;
}

h1, h2, h3 {
  font-family: "Jura", sans-serif;
}

.disc {
  list-style: disc;
  padding-left: 16px;
}

.m-0 {
  margin: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.bold {
  font-weight: 700;
}

.just-validate-error-field.form-input {
  border: 2px solid #ff3636;
}

.just-validate-error-label {
  display: none;
}

.submit {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.submit main {
  align-self: center;
  text-align: center;
}

.header {
  position: absolute;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  background-color: transparent;
}
.header-privacy {
  background-color: #FFFFFF;
}
@media screen and (min-width: 1200px) {
  .header .container {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.header .navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header .burger-btn {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
}
@media screen and (min-width: 1200px) {
  .header .burger-btn {
    display: none;
  }
}
.header-list {
  display: none;
}
@media screen and (min-width: 1200px) {
  .header-list {
    display: flex;
    gap: 8px;
    border-radius: 40px;
    overflow: hidden;
    background: #FFFFFF;
    border: 2px solid #105c9a;
  }
}
.header-list__item a {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #105c9a;
  padding: 16px 22px;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.header-list__item a:hover, .header-list__item a:focus {
  color: #469ee6;
}

.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #2f2f2f;
  color: #FFFFFF;
}
.footer .navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
  font-weight: 500;
  font-size: 14px;
}
@media screen and (min-width: 1200px) {
  .footer .navigation {
    flex-direction: row;
  }
}
.footer .link {
  margin-bottom: 16px;
  transition: opacity 0.3s ease-in-out, color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.footer .link:hover, .footer .link:focus {
  color: #469ee6;
}
.footer .text-wrapper {
  order: 2;
}
@media screen and (min-width: 1200px) {
  .footer .text-wrapper {
    order: 0;
  }
}
.footer .list.tel-mail a {
  font-size: 12px;
}

/* backdrop при відкривтому модальному вікні */
/*  backdrop що огортає враппер модалки */
/*  показати backdrop що огортає враппер модалки */
/* @media all and (max-width:767px){
    .hystmodal__close{
        top:10px;
        right: 10px;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M22 2L2 22'/%3E%3Cpath fill='none' stroke='%23111' stroke-linecap='square' stroke-miterlimit='50' stroke-width='2' d='M2 2l20 20'/%3E%3C/svg%3E");
    }
    .hystmodal__window{
        margin: 0;
    }
} */
/* fix html, body */
.hystmodal__opened {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden;
}

/* modal backdrop */
.hystmodal__shadow {
  position: fixed;
  border: none;
  display: block;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 98;
  opacity: 0;
  transition: opacity 0.15s ease;
  background-color: black;
}

/* backdrop при відкривтому модальному вікні */
.hystmodal__shadow--show {
  pointer-events: auto;
  opacity: 0.6;
}

/*  backdrop що огортає враппер модалки */
.hystmodal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  pointer-events: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  z-index: 99;
  visibility: hidden;
}

/*  показати backdrop що огортає враппер модалки */
.hystmodal--active {
  pointer-events: auto;
  opacity: 1;
}
.hystmodal--active .hystmodal__window {
  opacity: 1;
}

.hystmodal--moved {
  pointer-events: auto;
  opacity: 1;
}

.hystmodal__wrap {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.hystmodal__window {
  position: relative;
  margin: 50px 0;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  width: 600px;
  max-width: 100%;
  overflow: visible;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.hystmodal__close {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  display: block;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  font-size: 0;
  cursor: pointer;
  outline: none;
  transition: color 300ms ease-in-out;
}
.hystmodal__close svg {
  fill: currentColor;
}
.hystmodal__close:hover {
  color: #469ee6;
}
.hystmodal__close:focus {
  color: #469ee6;
  outline: 2px dotted #afb3b9;
  outline-offset: 2px;
}
.hystmodal__close:active {
  color: inherit;
}

/* ------------ pop-up */
/* ------------ burger */
.hystmodal.hystmodal--burger .hystmodal__wrap {
  justify-content: flex-start;
}
.hystmodal.hystmodal--burger .hystmodal__window {
  margin: 0;
  width: 100%;
  height: 100%;
  color: #000000;
  background-color: #FFFFFF;
  padding-top: 33px;
  padding-bottom: 30px;
}
.hystmodal.hystmodal--burger .hystmodal__window .container {
  height: 100%;
}
.hystmodal.hystmodal--burger .hystmodal__close--btn {
  top: 33px;
  right: 33px;
  color: #000000;
}
.hystmodal.hystmodal--burger .burger-list {
  padding-top: 50px;
}

.hero {
  background-color: #000000;
}
.hero.bg {
  background-position: top 0 center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/1.webp");
}
@media screen and (min-width: 1200px) {
  .hero.bg {
    background-position: top -140px center;
  }
}
.hero .content-wrapper {
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  .hero .content-wrapper {
    justify-content: flex-end;
  }
}

.about .content-wrapper {
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
}
.about .title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
}
.about .image-title {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.how {
  background: #f2f3ee;
}
.how .content-wrapper {
  padding-left: 24px;
  padding-right: 24px;
  max-width: 952px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
}
.how .title {
  font-size: 30px;
  text-transform: uppercase;
}
.how .subtitle {
  font-size: 22px;
  text-transform: uppercase;
}
.how .list__item {
  margin-bottom: 24px;
}

.gallery {
  background: #469ee6;
  color: #FFFFFF;
}
.gallery .title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
}
.gallery .list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
  padding: 24px;
  margin-bottom: 32px;
}
.gallery .list__item {
  width: 100%;
  border-radius: 8px;
}
@media screen and (min-width: 1200px) {
  .gallery .list__item {
    max-width: 450px;
  }
}
.gallery .list .image {
  width: 100%;
}
.gallery .text {
  font-weight: 500;
  text-align: center;
}

.contacts .content-wrapper {
  border-radius: 16px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
}
@media screen and (min-width: 768px) {
  .contacts .content-wrapper {
    flex-direction: row;
  }
}
.contacts .text-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-weight: 500;
}
.contacts .title {
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;
}

.privacy {
  padding-top: 120px;
  font-size: 18px;
  font-weight: 400;
  min-height: calc(100vh - 120px);
  color: #000000;
  background-color: #FFFFFF;
}
.privacy .title {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
}
.privacy .wrapper {
  max-width: 1040px;
  margin: 0 auto;
}