// ========================================= common start
html,
body {
  font-family: $main-font;
  background-color: $main-bg;

  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  color: $black;
  
  margin: 0 auto;
  width: 100%;

  scroll-behavior: smooth;
  overflow-x: hidden;
}
// body.hidden {
//   overflow: hidden;
// }

section{
  padding: 60px 0;
}

// ------------------------------  logo 
.logo{
  font-family: "Grandstander", sans-serif;
  font-weight: 800;
  font-size: 18px;
  text-transform: uppercase;
  color:  #ae1412;
  transition: $transition;
  max-width: max-content;

  &:hover, &:focus {
      color: $accent;
    }
  
  &:active {
    color: second;
  }
}

// ------------------------------ links 

.app-link{
  max-width: max-content;
  transition: $transition;
  border-radius: 12px;
  &:hover,&:focus{
    -webkit-box-shadow: 0px 15px 12px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 15px 12px -8px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 15px 12px -8px rgba(0, 0, 0, 0.75);
  }
  &:active{
    box-shadow: none;
  }

}

// ------------------------------ headings 1  2  3
h1,h2,h3 {
  font-family: "Jura", sans-serif;
}

// ------------------------------ headings 1  2  3


.disc{
  list-style: disc;
  padding-left: 16px;
}



//margin

.m-0{
  margin: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-14 {
  margin-bottom: 14px;
}
.mb-16{
  margin-bottom: 16px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-24{
  margin-bottom: 24px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.bold{
  font-weight: 700;
}

//  ------------------------------  for form validate

.just-validate-error-field.form-input{
  border:2px solid $error
}

.just-validate-error-label{

  display: none;
}



//  ------------------------------  mobile/tablet/desktop - hidden 

// _______________________________ submit page start
.submit{
  height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & main{
      align-self: center;
      text-align: center;
    }



}

    // _______________________________ submit page end

// ========================================= common end





