// =========================== privacy & terms start 
.privacy{
    padding-top: 120px;
    font-size: 18px;
    font-weight: 400;
    min-height: calc( 100vh - 120px );
    color: $black;
    background-color: $white;

    .title{
        font-family: $main-font;
        font-size: 40px;
        font-weight: 800;
        line-height: 1.2;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 40px;
    }

    & .wrapper{
        max-width: 1040px;
        margin: 0 auto;
    }
}


// =========================== privacy & terms end 